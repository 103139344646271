import React, { Fragment, useEffect, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import {
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@elastic/eui"
import Images from "../../images"
import { observer } from "mobx-react-lite"
import { useStore } from "../../stores"
import { ProcessStates, RepairTypes } from "../../entities/constants"
import SummaryItem from "../../entities/summaryItems"
import {
  getBookingDateTimeUIFormatted,
  getCompletionDateUIFormatted,
  removeSavedEntryPointUrl,
} from "../../entities/utilities"
import HailCXRepairTypeAlert from "../../components/hailcx-repairtype-alert"
import "../../styles/hailcx-description-lists.css"
import SummaryRepairLocation from "../../components/booking/summary-repairlocation"
import HailCXBookingCompletedFooter from "../../components/booking/booking-completed-footer"

const BookingConfirmedPage = () => {
  const [bookingSummary, setBookingSummary] = useState<SummaryItem[]>([])
  const { customerStore, facilityStore, bookingStore } = useStore()
  const { facility } = facilityStore
  const { booking } = bookingStore

  const data = {
    title: "Complete",
  }

  useEffect(() => {
    customerStore.saveProcessState(ProcessStates[2].Id, ProcessStates[2].Name)

    if (booking) {
      const repairType = RepairTypes.find(
        rt => rt.acronym === booking.repairType
      )


      if (repairType) {
        const summary = [
          {
            title: <EuiImage src={Images.CalendarIcon} alt="Completion" />,
            description: (
              <EuiText grow={false}>
                <strong>Estimated completion</strong>
                <br />
                {getCompletionDateUIFormatted(
                  booking?.repairDate,
                  repairType.durationInWeeks
                )}
                <br />
                <p className="highlightedText">
                  {repairType.durationDescription}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.Clock} alt="Drop Off" />,
            description: (
              <EuiText grow={false}>
                <p>
                  <strong>Drop off</strong>
                  <br />
                  {`${getBookingDateTimeUIFormatted(
                    booking?.repairDate,
                    booking?.dropOffTime
                  )}`}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.MapMarker} alt="Location" />,
            description: (
              <EuiText grow={false}>
                <SummaryRepairLocation facility={facility} />
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.Info} alt="Info" />,
            description: (
              <EuiText grow={false}>
                <p>
                  Your insurance excess will be due on completion of repair.
                </p>
              </EuiText>
            ),
          },
        ]

        setBookingSummary([...summary])
      }

      //with completion of new booking process, we remove the saved entry point url of new booking process
      removeSavedEntryPointUrl()
    }
  }, [])

  return (
    <HailCXLayout
      header={
        <HailCXRightImageHeader
          title={data.title}
          progressValue={100}
          vehicleInfo={customerStore.getVehicleInfo()}
        />
      }
      footer={<HailCXBookingCompletedFooter />}
    >
      <EuiFlexGroup gutterSize="l" justifyContent="spaceAround">
        <EuiFlexItem grow={false}>
          <EuiImage src={Images.Confirmed} alt="Confirmed" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText
            grow={false}
            className="eui-textLeft"
            style={{ marginRight: "auto", marginLeft: "auto" }}
          >
            <h1>You're all done!</h1>
            <p>
              A confirmation email & SMS has been sent. <br />
              Please also check your spam/junk email folder.
            </p>
            <p>
              If you would like to be added to the Waitlist for a possible
              earlier repair, press Waitlist button below and then close this
              page. Otherwise you can close this page now.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiPanel>
        <EuiDescriptionList type="column" listItems={bookingSummary} />
      </EuiPanel>
      <HailCXRepairTypeAlert />
    </HailCXLayout>
  )
}

export default observer(BookingConfirmedPage)
